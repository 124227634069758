.footerContainer {
  background: #A2171F;
  height: 60px;
}

.footerGrid {
  height: 100%;
}

.footerIconTextContainer {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  margin-right: 20px;
}

.footerIcon {
  display: flex;
  margin-right: 10px;
  height: 20px;
  width: 20px;
}

.footerText {
  color: #fff;
  font-size: 0.75rem;
}

.footerGogigLogo {
  height: 20px;
}

.footerLink {
  text-decoration: none;
  cursor: pointer;
}

.footerLink:hover {
  color: #fff;
  text-decoration: underline;
}

.footerSectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerGogigTypography {
  color: #fff;
  font-size: 0.875rem !important;
  font-family: 'Montserrat' !important;
  margin-right: 10px !important;
}

@media (max-width: 901px) {
  .footerContainer {
    height: fit-content;
  }
  .footerIconTextContainer {
    padding-bottom: 10px;
  }
  .footerSectionContainer {
    padding: 20px;
  }
}